import { MutableRefObject, useContext, useEffect, useState } from 'react'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Container from '@mui/material/Container'
import IconButton from '@mui/material/IconButton'
import Toolbar from '@mui/material/Toolbar'
import { AuthContext } from 'auth/context'
import {
    COLOR_BLACK,
    COLOR_GRAY_300,
    COLOR_WHITE,
    SIZE_6,
    SIZE_16,
    SIZE_24,
    theme
} from 'styles/theme'
import { LogoColorType, LogoComponent } from 'components/Logo/LogoComponent'
import { MobileNavbarComponent } from './MobileNavbarComponent'
import { MyAccount } from 'components/myAccount'
import { NavbarState, ShoppingCartState } from 'store'
import { OffCanvasNavComponent } from './OffCanvasNavComponent'
import { useAppDispatch, useAppSelector, useNav, useUser } from 'hooks'
import { useCart } from 'hooks/useCart'
import { useRouter } from 'next/router'
import { useStyles } from './Navbar.styles'
import CountDown from 'components/countDown'
import ExpiresResetsLeft from 'components/expiresResetsLeft'
import ShoppingBag from 'components/shoppingBag'
import ShoppingCartExpiresModal from 'components/shoppingCartExpiresModal'
import { useLocalStorage } from 'hooks/useLocalStorage'
import { INIT_UUID } from 'consts'

export type NavbarProps = {
    isMobileWidth: boolean
}

type CountdownComponentType = {
    countDownFinishedCallback: () => void
    expires: string | Date
    isMobileWidth: boolean
    isNavExpanded: boolean
    isScrolledOrHovered: boolean
    saveCart: MutableRefObject<ShoppingCartState | null>
    shouldShowTimer: boolean
    shouldStop: boolean
}

const CountdownComponent = ({
    countDownFinishedCallback,
    expires,
    isMobileWidth,
    isNavExpanded,
    isScrolledOrHovered,
    saveCart,
    shouldShowTimer,
    shouldStop
}: CountdownComponentType) => {
    const countDownText = isMobileWidth ? 'Reserved For mm:ss' : 'Package Reserved For mm:ss'

    return (
        <Box
            alignItems="center"
            display="flex"
            flexDirection="row"
            style={{
                display: shouldShowTimer ? '' : 'none'
            }}
        >
            <CountDown
                isScrolled={isScrolledOrHovered || isNavExpanded}
                onExpiredCallBack={countDownFinishedCallback}
                pause={shouldStop}
                text={countDownText}
                time={expires || saveCart.current?.expires || new Date()}
            />
        </Box>
    )
}

export const NavbarComponent = ({ isMobileWidth }: NavbarProps): JSX.Element => {
    const [authState] = useContext(AuthContext) ?? []
    const dispatch = useAppDispatch()
    const router = useRouter()
    const { isNavbarHidden, isNotHomePage, isScrolled } = useNav()
    const { isLoggedIn } = authState
    const navState: NavbarState = useAppSelector((state) => state.navbarReducer)
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
    const [isUserLoggedIn, setIsUserLoggedIn] = useState(isLoggedIn)
    const [isHovered, setIsHovered] = useState<boolean>(false)
    const isMenuOpen = Boolean(anchorEl)
    const isScrolledOrHovered = isScrolled || isHovered
    const isNotMobileOrHomePage = !isMobileWidth && !isNotHomePage
    const shouldShowNavbar =
        isNotHomePage || isScrolledOrHovered || navState.expandedNav || isMenuOpen
    const firstName = authState?.user?.firstName ?? ''
    const lastName = authState?.user?.lastName ?? ''
    const userName = authState?.user?.name ? authState?.user?.name : firstName + ' ' + lastName
    const userProfileImage = authState?.user?.profileImage ?? ''
    const { getLocalStorage, setLocalStorage } = useLocalStorage()
    const {
        cartInfo,
        countDownFinishedCallback,
        queryParams,
        refuseResetExpirationTime,
        resetExpirationTime,
        saveCart,
        setShowExpiresModal,
        shoppingBagClickCallback,
        shoppingCart,
        shouldShowShoppingBag,
        shouldShowTimer,
        shouldStop,
        showExpiresModal,
        showExpiresResetsModal
    } = useCart({
        navState,
        isLoggedIn,
        isNavExpanded: navState.expandedNav
    })
    const { logout } = useUser()
    const { classes } = useStyles()
    const shouldShowCountdown: boolean = cartInfo?.shouldShowCountdown ?? false

    const handleMouseEnter = () => setIsHovered(true)

    const handleMouseLeave = () => setIsHovered(false)

    const handleExpandNavClick = () => {
        if (navState.expandedNav) {
            dispatch({ type: 'navbar/setState', payload: { expandedNav: false } })
        } else {
            dispatch({ type: 'navbar/setState', payload: { expandedNav: true, menu: true } })
        }
    }

    const handleShoppingCartExpiresClick = () => {
        setShowExpiresModal(false)
        setLocalStorage('addToCartInfo', { ...cartInfo, hasBeenOpenedRoomPage: false })
        setLocalStorage('addToCartInfo', {
            ...cartInfo,
            checkoutId: '',
            checkoutSecret: '',
            seating: [],
            seatingToken: '',
            shouldResetSeating: true
        })
        let url = `/events/${getLocalStorage('eventSlug')}${queryParams}`
        if (shoppingCart.purchaseAddOnsMemberId !== INIT_UUID) {
            const orderId = getLocalStorage('orderId')
            url = `/orders/${orderId}`
        }
        window.location.href = url
    }

    useEffect(() => {
        setIsUserLoggedIn(isLoggedIn)
    }, [isLoggedIn])

    return (
        <>
            {!isNavbarHidden && (
                <>
                    <AppBar
                        position="fixed"
                        className={`${classes.appBar} ${
                            shouldShowNavbar ? classes.appBarScrolled : undefined
                        }`}
                        onMouseEnter={isNotMobileOrHomePage ? handleMouseEnter : undefined}
                        onMouseLeave={isNotMobileOrHomePage ? handleMouseLeave : undefined}
                    >
                        <Toolbar
                            className={classes.toolbarWrapper}
                            style={{
                                paddingLeft: isMobileWidth || !isNotHomePage ? 0 : undefined,
                                paddingRight: isMobileWidth || !isNotHomePage ? 0 : undefined
                            }}
                        >
                            <Container
                                className={classes.navItemsContainer}
                                style={{
                                    maxWidth: !isNotHomePage ? '1800px' : undefined
                                }}
                                sx={{
                                    paddingLeft: isNotHomePage && isMobileWidth ? 0 : undefined,
                                    paddingRight: isNotHomePage && isMobileWidth ? 0 : undefined,
                                    '@media (min-width: 600px)': {
                                        padding: isNotHomePage ? 0 : undefined
                                    },
                                    '@media (min-width: 768px)': {
                                        maxWidth: isNotHomePage ? '738px' : undefined,
                                        padding: isNotHomePage ? 0 : undefined
                                    },
                                    '@media (min-width: 990px) and (max-width: 1200px)': {
                                        padding: isNotHomePage ? '0 !important' : undefined
                                    },
                                    '@media (min-width: 991px)': {
                                        maxWidth: isNotHomePage ? '928px' : undefined,
                                        padding: isNotHomePage ? `0 ${SIZE_24}` : undefined
                                    },
                                    '@media (min-width: 1200px) and (max-width: 1370px)': {
                                        maxWidth: isNotHomePage ? '1160px' : undefined,
                                        padding: isNotHomePage ? 0 : undefined
                                    },
                                    '@media (min-width: 1370px)': {
                                        minWidth: isNotHomePage ? '1280px' : undefined,
                                        padding: isNotHomePage ? 0 : undefined
                                    },
                                    '@media (max-width: 768px)': {
                                        padding: isMobileWidth
                                            ? `0 ${SIZE_6} 0 ${SIZE_16}`
                                            : undefined
                                    },
                                    '@media (max-width: 991px)': {
                                        padding:
                                            isNotHomePage && !isMobileWidth
                                                ? `0 ${SIZE_24}`
                                                : undefined
                                    },
                                    '@media (max-width: 1200px)': {
                                        padding:
                                            isNotHomePage && !isMobileWidth
                                                ? `0 ${SIZE_24}`
                                                : undefined
                                    },
                                    '@media (max-width: 1370px)': {
                                        padding:
                                            isNotHomePage && !isMobileWidth
                                                ? `0 ${SIZE_24}`
                                                : undefined
                                    },
                                    [theme.breakpoints.down('md')]: {
                                        paddingLeft: !isMobileWidth ? SIZE_24 : undefined,
                                        paddingRight: !isMobileWidth ? SIZE_24 : undefined
                                    },
                                    [theme.breakpoints.up('md')]: {
                                        paddingLeft: '56px',
                                        paddingRight: '56px'
                                    }
                                }}
                            >
                                {isMobileWidth && (navState.signIn || navState.signUp) ? (
                                    navState.signIn ? (
                                        <div className={classes.navbarText}>Sign In</div>
                                    ) : (
                                        <div className={classes.navbarText}>Sign Up</div>
                                    )
                                ) : (
                                    <div className={classes.navbarLogo}>
                                        {shouldShowNavbar && (
                                            <IconButton
                                                disableRipple
                                                onClick={() => router.push('/')}
                                                style={{
                                                    paddingLeft: isMobileWidth ? undefined : 0
                                                }}
                                            >
                                                <LogoComponent
                                                    color={LogoColorType.BLACK}
                                                    height="44px"
                                                    width="94px"
                                                />
                                            </IconButton>
                                        )}
                                    </div>
                                )}
                                <div>
                                    <>
                                        <Box
                                            alignItems="center"
                                            display="flex"
                                            flexDirection="row"
                                        >
                                            {shouldShowTimer && (
                                                <CountdownComponent
                                                    isScrolledOrHovered={
                                                        !isNotHomePage ? isScrolledOrHovered : true
                                                    }
                                                    isMobileWidth={isMobileWidth}
                                                    isNavExpanded={navState.expandedNav}
                                                    countDownFinishedCallback={
                                                        countDownFinishedCallback
                                                    }
                                                    expires={shoppingCart?.expires}
                                                    shouldShowTimer={
                                                        shouldShowTimer && shouldShowCountdown
                                                    }
                                                    shouldStop={shouldStop}
                                                    saveCart={saveCart}
                                                />
                                            )}
                                            {shouldShowShoppingBag && (
                                                <ShoppingBag
                                                    clickCallback={shoppingBagClickCallback}
                                                    isScrolled={
                                                        isMobileWidth && navState.expandedNav
                                                            ? false
                                                            : !isNotHomePage && !isScrolledOrHovered
                                                    }
                                                />
                                            )}
                                            {isMobileWidth && (
                                                <MobileNavbarComponent
                                                    handleNavClick={handleExpandNavClick}
                                                    isLoggedIn={isLoggedIn}
                                                    isNavExpanded={navState.expandedNav}
                                                    isScrolled={isScrolled}
                                                    isNotHomePage={isNotHomePage}
                                                    userName={userName}
                                                    userProfileImage={userProfileImage}
                                                />
                                            )}
                                            {!isMobileWidth && isUserLoggedIn && (
                                                <MyAccount
                                                    anchorEl={anchorEl}
                                                    isHovered={isHovered}
                                                    isMenuOpen={isMenuOpen}
                                                    isNotHomePage={isNotHomePage}
                                                    isScrolled={isScrolled}
                                                    logout={logout}
                                                    setAnchorEl={setAnchorEl}
                                                    shouldShowShoppingBag={shouldShowShoppingBag}
                                                    userName={userName}
                                                    userProfileImage={userProfileImage}
                                                />
                                            )}
                                            {!isMobileWidth && !isUserLoggedIn && (
                                                <Button
                                                    aria-label="sign in button"
                                                    className={classes.signInButton}
                                                    color="inherit"
                                                    disableElevation
                                                    onClick={() =>
                                                        dispatch({
                                                            type: 'navbar/setState',
                                                            payload: { signIn: true }
                                                        })
                                                    }
                                                    sx={{
                                                        borderColor:
                                                            isScrolledOrHovered || isNotHomePage
                                                                ? COLOR_GRAY_300
                                                                : undefined,
                                                        color:
                                                            isScrolledOrHovered || isNotHomePage
                                                                ? COLOR_BLACK
                                                                : COLOR_WHITE,
                                                        '&:hover': {
                                                            backgroundColor: 'primary.main',
                                                            borderColor: 'primary.main',
                                                            color: COLOR_WHITE
                                                        }
                                                    }}
                                                    variant="outlined"
                                                >
                                                    Sign In
                                                </Button>
                                            )}
                                        </Box>
                                    </>
                                </div>
                            </Container>
                        </Toolbar>
                    </AppBar>
                </>
            )}
            {navState.expandedNav && <OffCanvasNavComponent />}
            {shouldShowTimer && (
                <>
                    <ExpiresResetsLeft
                        show={showExpiresResetsModal}
                        onNoClick={refuseResetExpirationTime}
                        onYesClick={resetExpirationTime}
                    />
                    <ShoppingCartExpiresModal
                        show={showExpiresModal}
                        onClick={handleShoppingCartExpiresClick}
                    />
                </>
            )}
        </>
    )
}
