import { DocumentNode, gql } from '@apollo/client'

export const FREE_SEAT_MUTATION: DocumentNode = gql`
    mutation Free($input: FreeInput!) {
        free(input: $input) {
            error
            status
        }
    }
`
