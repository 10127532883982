import { DocumentNode, gql } from '@apollo/client'

export const CREATE_CHECKOUT_MUTATION: DocumentNode = gql`
    mutation Checkout($input: CheckoutRequest!) {
        checkout(input: $input) {
            error
            id
            message
            secret
            status_code
        }
    }
`
