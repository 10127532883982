import { CheckoutType } from 'enums'
import { MutableRefObject } from 'react'

export type Transaction = {
    eventId: string
    items: TicketItem[]
    salesChannelId: string
    seatingReservationToken: string
    type: CheckoutType.TRANSACTION
}

export type TicketItem = {
    id: string
    amount: number
    asHardTicket: boolean
    categoryRef: string
    name: string
    price: number
    seatingInfo: SeatingInfo
    taxRate: number
    ticketTypeId: string
    type: string
}

export type SeatingInfo = {
    [key: string]: any
    id: string
    type: number
    categoryId: string
    rowName: string
    seatingGroupId: string
    seatName: string
    seatType: string | null
    sectionName: string
    statusId: string
}

export type Seat = {
    [key: string]: any
    id: string
    rowName: string
    seatName: string
    seatType: string | null
    sectionName: string
}

export type UseSeatingReturnType = {
    cancelReservation: () => Promise<void>
    checkoutId: string
    errorMessage: string
    getSeatMapEventById: (eventId: string) => Promise<SeatMapEventType>
    handleSaveSeats: () => void
    isLoading: boolean
    isReserving: boolean
    isTimerModalOpen: boolean
    mapRef: MutableRefObject<HTMLDivElement | null>
    onRemoveSeat: (seatId: string) => void
    scriptRef: MutableRefObject<HTMLDivElement | null>
    selectedSeats: any[]
    setErrorMessage: (message: string) => void
    setIsTimerModalOpen: (isOpen: boolean) => void
    timeLeft: number
    unwrapSeatingEventById: (isInitial: boolean, ticketName?:string) => Promise<string[]>
}

export type UseSeatingPropType = {
    domain?: string
    groupSize?: number
    seatMapEventId: string
}

export type CategoryType = {
    name: string
    ref: string
    seatingReference: string
    v: number
}

export type SeatMapEventType = {
    id: string
    categories: CategoryType[]
    seatingEventId: string
    tickets: TicketType[]
}

export type TicketType = {
    active: boolean
    categoryRef: string
    name: string
    price: number
    v: number
}

export type TicketRefType = {
    categoryRef: string
    id: string
    name: string
    price: number
    secret: string
    taxRate: number
}

export const defaultTicketObject = {
    categoryRef: '',
    id: '',
    name: '',
    price: 0,
    secret: '',
    taxRate: 0
}

export const emptySeatMapObject = { id: '', categories: [], seatingEventId: '', tickets: [] }
