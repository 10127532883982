export const checkNamesInArrayMatch = (array1: { name: string }[], array2: { name: string }[]) => {
    const name1 = array1.map((item) => item.name.toLowerCase())
    const name2 = array2.map((item) => item.name.toLowerCase())
    return name1 === name2 ? [] : name2.filter((name) => name1.includes(name))
}
export const getVivenuObjectByName = (name: string, items: any[]): any => {
    // We will need to match by name as the id's won't be the same from the Vivenu API
    const item = items.find((item) => item.name.toLowerCase() === name.toLowerCase())
    return item
}
