import { useEffect, useState } from 'react'

export type WidthReturnType = {
    isMobileWidth: boolean
    windowWidth: number | null
}

export const useWindowWidth = (): WidthReturnType => {
    const [windowWidth, setWindowWidth] = useState<number | null>(null)
    const [isMobileWidth, setIsMobileWidth] = useState<boolean>(false)
    useEffect(() => {
        const handleResize = () => {
            const innerWindowWidth = window.innerWidth
            setWindowWidth(innerWindowWidth)
        }

        window.addEventListener('resize', handleResize)

        handleResize()

        return (): void => {
            window.removeEventListener('resize', handleResize)
        }
    }, [])

    useEffect(() => {
        setIsMobileWidth(window.innerWidth <= 768)
    }, [])

    return { isMobileWidth, windowWidth }
}
