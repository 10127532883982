import { DocumentNode, gql } from '@apollo/client'

export const ABORT_CHECKOUT_MUTATION: DocumentNode = gql`
    mutation Abort($input: AbortCheckoutInput!) {
        abortCheckout(input: $input) {
            error
            status
        }
    }
`
