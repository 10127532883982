import { createTheme } from '@mui/material/styles'
import { makeStyles } from 'tss-react/mui'

declare module '@mui/material/styles' {
    interface Palette {
        light: Palette['primary']
    }

    // allow configuration using `createTheme`
    interface PaletteOptions {
        light?: PaletteOptions['primary']
    }
}

// Update the Button's color prop options
declare module '@mui/material/Button' {
    interface ButtonPropsColorOverrides {
        light: true
    }
}

export enum InputVariant {
    CONTAINED = 'contained',
    FILLED = 'filled',
    OUTLINED = 'outlined'
}

export const CENTER = 'center'
export const FILLED_INPUT_VARIANT = 'filled'

export enum ColorPalette {
    ERROR = 'error',
    INFO = 'info',
    INHERIT = 'inherit',
    LIGHT = 'light',
    PRIMARY = 'primary',
    SECONDARY = 'secondary',
    SUCCESS = 'success',
    WARNING = 'warning'
}

export enum Sizes {
    LARGE = 'large',
    MEDIUM = 'medium',
    SMALL = 'small'
}

// SKELETON VARIANTS
export const SKELETON_VARIANT_RECTANGULAR = 'rectangular'
export const SKELETON_VARIANT_ROUNDED = 'rounded'

// COLORS:
export const COLOR_BLACK = '#000000'
export const COLOR_BLUE_50 = '#1352DF'
export const COLOR_BLUE_100 = '#F8FAFF'
export const COLOR_BLUE_200 = '#D2E3FC'
export const COLOR_BLUE_700 = '#175CD3'
export const COLOR_BLUE_800 = '#001774'
export const COLOR_BLUE_GRAY_50 = '#F8F9FC'
export const COLOR_BLUE_GRAY_700 = '#363F72'
export const COLOR_BLUE_GRAY_800 = '#3C4043'
export const COLOR_ERROR = '#D92D20'
export const COLOR_GRAY_50 = '#F9FAFB'
export const COLOR_GRAY_100 = '#F2F4F7'
export const COLOR_GRAY_200 = '#E4E7EC'
export const COLOR_GRAY_300 = '#D0D5DD'
export const COLOR_GRAY_400 = '#98A2B3'
export const COLOR_GRAY_500 = '#667085'
export const COLOR_GRAY_600 = '#475467'
export const COLOR_GRAY_700 = '#344054'
export const COLOR_GRAY_800 = '#1D2939'
export const COLOR_GRAY_BG = '#F4F5F7'
export const COLOR_GREEN_25 = '#F6FEF9'
export const COLOR_GREEN_50 = '#ECFDF3'
export const COLOR_GREEN_300 = '#6CE9A6'
export const COLOR_GREEN_500 = '#00BF6F'
export const COLOR_GREEN_600 = '#027A48'
export const COLOR_GREEN_800 = '#05603A'
export const COLOR_PRIMARY_25 = '#FCFAFF'
export const COLOR_PRIMARY_50 = '#F9F5FF'
export const COLOR_PRIMARY_100 = '#F4EBFF'
export const COLOR_PRIMARY_200 = '#E9D7FE'
export const COLOR_PRIMARY_300 = '#D6BBFB'
export const COLOR_PRIMARY_500 = '#9E77ED'
export const COLOR_PRIMARY_600 = '#7F56D9'
export const COLOR_PRIMARY_700 = '#6941C6'
export const COLOR_PRIMARY_800 = '#53389E'
export const COLOR_RED_50 = '#FEF3F2'
export const COLOR_RED_100 = '#FEE4E2'
export const COLOR_RED_200 = '#FECDCA'
export const COLOR_RED_700 = '#B42318'
export const COLOR_RED_800 = '#912018'
export const COLOR_SUCCESS = '#027A48'
export const COLOR_WARNING_50 = '#FFFAEB'
export const COLOR_WARNING_700 = '#B54708'
export const COLOR_WHITE = '#FFFFFF'

// FONT FAMILY
export const FONT_FAMILY_INTER = 'Inter'
export const FONT_FAMILY_FUTURA_PT = 'FuturaPt'
export const FONT_FAMILY_SOURCE_SANS_PRO = `'Source Sans 3', sans-serif`
export const FONT_FAMILY_GOOGLE_SANS = `'Google Sans', arial, sans-serif`

// FONT SIZES:
export const FONT_SIZE_4 = '0.25rem'
export const FONT_SIZE_8 = '0.5rem'
export const FONT_SIZE_10 = '0.625rem'
export const FONT_SIZE_12 = '0.75rem'
export const FONT_SIZE_14 = '0.875rem'
export const FONT_SIZE = '1rem'
export const FONT_SIZE_18 = '1.125rem'
export const FONT_SIZE_20 = '1.25rem'
export const FONT_SIZE_24 = '1.5rem'
export const FONT_SIZE_32 = '2rem'
export const FONT_SIZE_48 = '3rem'
export const FONT_SIZE_64 = '4rem'

// FONT WEIGHT:
export const FONT_WEIGHT_300 = '300'
export const FONT_WEIGHT_400 = '400'
export const FONT_WEIGHT_500 = '500'
export const FONT_WEIGHT_600 = '600'
export const FONT_WEIGHT_700 = '700'

// SIZES:
export const SIZE_2 = '2px'
export const SIZE_4 = '4px'
export const SIZE_6 = '6px'
export const SIZE_8 = '8px'
export const SIZE_10 = '10px'
export const SIZE_12 = '12px'
export const SIZE_14 = '14px'
export const SIZE_16 = '16px'
export const SIZE_18 = '18px'
export const SIZE_20 = '20px'
export const SIZE_22 = '22px'
export const SIZE_24 = '24px'
export const SIZE_28 = '28px'
export const SIZE_30 = '30px'
export const SIZE_32 = '32px'
export const SIZE_36 = '36px'
export const SIZE_38 = '38px'
export const SIZE_40 = '40px'
export const SIZE_42 = '42px'
export const SIZE_44 = '44px'
export const SIZE_46 = '46px'
export const SIZE_48 = '48px'
export const SIZE_50 = '50px'
export const SIZE_54 = '54px'
export const SIZE_56 = '56px'
export const SIZE_60 = '60px'
export const SIZE_64 = '64px'
export const SIZE_68 = '68px'
export const SIZE_70 = '70px'
export const SIZE_74 = '74px'
export const SIZE_76 = '76px'
export const SIZE_78 = '78px'
export const SIZE_80 = '80px'
export const SIZE_84 = '84px'
export const SIZE_98 = '98px'
export const SIZE_100 = '100px'
export const SIZE_120 = '120px'

// BORDERS
export const BORDER = `1px solid ${COLOR_GRAY_200}`
export const ERROR_BORDER = `1px solid ${COLOR_ERROR}`

// BORDER RADIUS
export const BORDER_RADIUS = `${SIZE_10}`

// BORDER TRANSPARENT
export const BORDER_TRANSPARENT = '1px solid transparent'

// BOX SHADOW
export const BOX_SHADOW = '0px 1px 2px rgba(16, 24, 40, 0.05)'

// COMMON INPUT SIZE
export enum InputSize {
    LARGE = 'large',
    MEDIUM = 'medium',
    SMALL = 'small'
}

// MENU BOX SHADOW
export const MENU_BOX_SHADOW = '0 1px 3px rgba(16,24,40,.1), 0 1px 2px rgba(16,24,40,.06)'

//WIDTH
export const HALF_WIDTH = '50%'
export const FULL_WIDTH = '100%'

// FULL HEIGHT
export const FULL_HEIGHT = '100%'

//DISPLAY
export const FLEX = 'flex'

export const theme = createTheme({
    palette: {
        error: {
            main: COLOR_ERROR
        },
        light: {
            contrastText: COLOR_PRIMARY_600,
            main: COLOR_PRIMARY_50
        },
        primary: {
            contrastText: COLOR_PRIMARY_50,
            dark: '#53389E',
            light: COLOR_PRIMARY_200,
            main: COLOR_PRIMARY_600
        },
        secondary: {
            contrastText: COLOR_GRAY_500,
            dark: COLOR_GRAY_800,
            light: '#B692F6',
            main: COLOR_PRIMARY_50
        }
    },
    typography: {
        fontFamily: 'Inter'
    }
})

export const useStyles = makeStyles()({
    active: {
        '& >': {
            border: `1px solid ${theme.palette.primary.dark}`,
            color: theme.palette.primary.main
        }
    },
    elementToStretch: {
        backgroundSize: 'cover',
        height: '100%',
        left: 0,
        overflow: 'hidden',
        position: 'absolute',
        top: 0,
        width: '100%'
    },
    imageOverlay: {
        width: '100px',
        height: '71px',
        position: 'absolute',
        bottom: '8px',
        right: '8px',
        border: `1px solid ${COLOR_WHITE}`,
        borderRadius: ' 2px',
        overflow: 'hidden',
        cursor: ' pointer',
        '& img': {
            position: 'absolute',
            width: '100%',
            height: '100%',
            top: '0',
            left: '0'
        },
        '@media (max-width: 767px)': {
            width: ' 80px',
            height: ' 57px'
        }
    }
})
