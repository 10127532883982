import { DocumentNode, gql } from '@apollo/client'

export const CANCEL_RESERVATION_MUTATION: DocumentNode = gql`
    mutation CancelReservation($input: CancelReservationRequest!) {
        cancelReservation(input: $input) {
            ... on ErrorResponse {
                error
                message
                status_code
            }
            ... on ReservationDocument {
                id
            }
        }
    }
`
