export enum BraintreeErrors {
    PROCESSOR_DECLINED = 'PROCESSOR_DECLINED',
    FAILED = 'FAILED',
    GATEWAY_REJECTED = 'GATEWAY_REJECTED',
    VIVENU_CHECKOUT_FAILED = 'VIVENU_CHECKOUT_FAILED'
}

export const unauthorizedErrorMessage = 'Could not authorize credit card: '
export const vivenuCheckoutFailedErrorMessage = "We're having trouble processing your order. Please try again. If the problem persists, please contact us for assistance."
export const errorMessage =
    'There was a problem processing your payment. Please try again or use a different payment method. '

export const getErrorMessage = (error: string) => {
    switch (error) {
        case BraintreeErrors.PROCESSOR_DECLINED:
            return errorMessage + ' Processor declined.'
        case BraintreeErrors.FAILED:
            return errorMessage + ' Processor Network Unavailable.'
        case BraintreeErrors.GATEWAY_REJECTED:
            return errorMessage + ' Gateway Rejected.'
        case BraintreeErrors.VIVENU_CHECKOUT_FAILED :
            return vivenuCheckoutFailedErrorMessage
        default:
            return errorMessage
    }
}

export const handleCardVerificationOfErrorMsgByBraintree = (msg: string) => {
    switch (true) {
        case msg.includes('Authentication Required'):
            msg = 'Cardholder Authentication Required'
            break
        case msg.includes(
            'the checkout endpoint has been updated. Please refresh the page and try again'
        ):
            msg = 'An error occurred, please refresh and try again'
            break
    }
    return msg + '.'
}

export const handlePaymentErrorMsgByBraintree = (msg: string) => {
    let paymentErrorMsg = msg || 'Payment Failed'
    const errorWithoutPrefix = msg.startsWith(unauthorizedErrorMessage)
        ? msg.slice(unauthorizedErrorMessage.length)
        : msg

    paymentErrorMsg = getErrorMessage(errorWithoutPrefix)
    if (errorWithoutPrefix.includes('message')) {
        const msgData = JSON.parse(msg).authorizationProcessorResponse ?? JSON.parse(msg)

        switch (msgData.cvvResponse) {
            case 'DOES_NOT_MATCH':
                paymentErrorMsg = errorMessage + 'CVV does not match.'
                break
            case 'NOT_VERIFIED':
                paymentErrorMsg = errorMessage + 'CVV not verified.'
                break
            case 'ISSUER_DOES_NOT_PARTICIPATE':
                paymentErrorMsg = errorMessage + 'CVV issuer does not participate.'
                break
            default:
                if (msgData.legacyCode === null) {
                    paymentErrorMsg = errorMessage
                    break
                }
                paymentErrorMsg =
                    errorMessage +
                    handleCardVerificationOfErrorMsgByBraintree(
                        msgData.message ?? msgData.processorResponse.message ?? msgData
                    )
                break
        }
    }
    return paymentErrorMsg
}
