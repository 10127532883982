import { DocumentNode, gql } from '@apollo/client'

export const GET_EVENT_SEATING_PAGE_QUERY: DocumentNode = gql`
    query GetEventQuery($slug: String, $password: String) {
        getEvent(slug: $slug, password: $password) {
            addOnItems {
                hidden
                id
                name
                onSale
                price
                required
            }
            checkInDate
            checkOutDate
            hotels {
                id
                overBaseOccupancyFee
                rooms {
                    id
                    shoulderNights {
                        id
                        price
                        quantityAvailable
                        taxesAndFees
                    }
                    tickets {
                        id
                    }
                    tiers {
                        id
                        onSaleDate
                        price
                        prices {
                            id
                            price
                            quantityAvailable
                            taxesAndFees
                        }
                    }
                }
            }
            id
            seatMapEventId
            slug
            ticketExperiences {
                tiers {
                    id
                    price
                    taxBreakdown {
                        amount
                        name
                    }
                    taxesAndFees
                    ticketTierID
                }
            }
            tickets {
                id
                tiers {
                    id
                    price
                    quantityAvailable
                }
            }
            timezone
        }
    }
`
