import { useCallback, useEffect, useRef, useState } from 'react'
import { ApolloClient, HttpLink, InMemoryCache } from '@apollo/client'
import { v4 as uuid } from 'uuid'
import { useLocalStorage } from './useLocalStorage'
import {
    CategoryType,
    defaultTicketObject,
    emptySeatMapObject,
    SeatingInfo,
    SeatMapEventType,
    TicketItem,
    TicketRefType,
    TicketType,
    Transaction,
    UseSeatingPropType,
    UseSeatingReturnType
} from 'consts'
import { CheckoutType, CheckoutTicketType } from 'enums'
import { useCountdownTimer } from './useCountdownTimer'
import dayjs from 'dayjs'
import {
    ABORT_CHECKOUT_MUTATION,
    CREATE_CHECKOUT_MUTATION,
    CANCEL_RESERVATION_MUTATION,
    FREE_SEAT_MUTATION,
    RESERVE_SEAT_MUTATION
} from 'graphQL'
import getConfig from 'next/config'
import { checkNamesInArrayMatch, getVivenuObjectByName } from 'util/seating'
import { useRouter } from 'next/router'
import { useAppDispatch } from 'hooks'
import { upsertCart } from 'store/actions/fetchCart'

const { publicRuntimeConfig, serverRuntimeConfig } = getConfig()

const GO_BACKEND_GRAPHQL_URL =
    typeof window === 'undefined'
        ? serverRuntimeConfig.NEXT_PUBLIC_BACKEND_VIVENU_GRAPHQL_URL
        : publicRuntimeConfig.NEXT_PUBLIC_VIVENU_GRAPHQL_URL

// Apollo client for Golang backend
const golangBackendClient = new ApolloClient({
    link: new HttpLink({
        uri: GO_BACKEND_GRAPHQL_URL,
        headers: {
            accept: '*/*',
            'content-type': 'application/json;charset=UTF-8'
        }
    }),
    cache: new InMemoryCache()
})

export const useSeating = ({
    domain,
    groupSize,
    seatMapEventId
}: UseSeatingPropType): UseSeatingReturnType => {
    const router = useRouter()
    const dispatch = useAppDispatch()
    const { getLocalStorage, setLocalStorage } = useLocalStorage()
    const localCart = getLocalStorage('cart')
    const currentShoppingCart = getLocalStorage('addToCartInfo')
    const {
        checkoutId,
        checkoutSecret,
        eventID,
        hotelID,
        presaleCode,
        seating,
        seatingToken,
        selectedTicketName,
        shouldResetSeating
    } = currentShoppingCart
    const initialWidth = typeof window !== 'undefined' ? window.innerWidth : null
    const isMobileWidth = initialWidth !== null ? initialWidth <= 768 : false
    const isDomainProd = domain === 'vibee.com' || domain === 'www.vibee.com'
    const baseUrl = isDomainProd ? 'https://vivenu.com' : 'https://vivenu.dev'
    const seatMapUrl = isDomainProd ? 'https://seatmap.vivenu.com' : 'https://seatmap.vivenu.dev'
    const [canContinue, setCanContinue] = useState<boolean>(false)
    const [errorMessage, setErrorMessage] = useState<string>('')
    const [isLoading, setIsLoading] = useState(false)
    const [isReserving, setIsReserving] = useState<boolean>(false)
    const [isTimerModalOpen, setIsTimerModalOpen] = useState<boolean>(false)
    const [selectedSeats, setSelectedSeats] = useState<SeatingInfo[]>([])
    const [timer, setTimer] = useState<boolean>(false)
    const { timeLeft } = useCountdownTimer({
        onTimeExpired: async () => {
            if (tokenRef.current && !checkoutId) {
                cancelReservation()
            }
        },
        startTimer: timer
    })
    const eventRef = useRef<{ seatingEventId: string }>({ seatingEventId: '' })
    const eventIdRef = useRef<string>('')
    const mapRef = useRef<HTMLDivElement | null>(null)
    const scriptRef = useRef<HTMLDivElement | null>(null)
    const seatArrayRef = useRef<any[]>([])
    const tokenRef = useRef<string>(seatingToken ?? '')
    const ticketRef = useRef<TicketRefType>(defaultTicketObject)
    const vivenuInstanceRef = useRef<any>(null)

    const updateSeatingInCart = useCallback((eventProp: Record<string, any>) => {
        setLocalStorage('addToCartInfo', {
            ...getLocalStorage('addToCartInfo'),
            ...eventProp
        })
    }, [])

    const abortCheckout = useCallback(async (): Promise<void> => {
        if (tokenRef.current && shouldResetSeating && timeLeft === 0) {
            try {
                const { data } = await golangBackendClient.mutate({
                    mutation: ABORT_CHECKOUT_MUTATION,
                    variables: {
                        input: {
                            secret: checkoutSecret
                        }
                    }
                })
                if (data.abortCheckout.status === '201') {
                    vivenuInstanceRef.current.selectObjectsInSeatSelector([])
                    seatArrayRef.current = []
                    tokenRef.current = ''
                    setSelectedSeats([])
                    setIsReserving(false)
                    setTimer(false)
                    updateSeatingInCart({
                        checkoutSecret: '',
                        seating: [],
                        seatingToken: '',
                        seatTimer: null,
                        shouldResetSeating: false
                    })
                }
                return
            } catch (error) {
                console.error('Error canceling checkout: ', error)
                return
            }
        }
    }, [checkoutSecret])

    const filterItemsByType = (type: string) => localCart.items.filter((item: { type: string }) => item.type === type)

    const createCheckout = async (eventId: string, nextPageUrl: string): Promise<void> => {
        setIsLoading(true)
        const seatingItems: TicketItem[] = selectedSeats.map((seat) => {
            return {
                id: uuid(),
                amount: 1,
                asHardTicket: false,
                categoryRef: ticketRef.current.categoryRef,
                name: ticketRef.current.name,
                price: ticketRef.current.price ?? 0,
                seatingInfo: {
                    id: seat._id,
                    type: seat._type,
                    categoryId: seat.categoryId,
                    rowName: seat.rowName,
                    seatingGroupId: seat.seatingGroupId,
                    seatName: seat.seatName,
                    seatType: seat.seatType ? seat.seatType : '',
                    sectionName: seat.sectionName,
                    statusId: seat.statusId
                },
                taxRate: ticketRef.current.taxRate ?? 0,
                ticketTypeId: ticketRef.current.id,
                type: CheckoutTicketType.TICKET
            }
        })
        const transaction: Transaction = {
            eventId: eventId,
            items: seatingItems,
            salesChannelId: 'sch-web',
            seatingReservationToken: tokenRef.current,
            type: CheckoutType.TRANSACTION
        }
        try {
            const { data } = await golangBackendClient.mutate({
                mutation: CREATE_CHECKOUT_MUTATION,
                variables: { input: transaction }
            })
            if (data.checkout.secret !== '' && data.checkout.id !== '') {
                setIsReserving(false)
                setTimer(false)
                updateSeatingInCart({
                    checkoutId: data.checkout.id,
                    checkoutSecret: data.checkout.secret,
                    seating: selectedSeats,
                    seatingToken: tokenRef.current,
                    seatTimer: null,
                    shouldResetSeating: false,
                    shouldShowCountdown: true,
                    shouldShowSeating: true
                })
                const ticketItems = filterItemsByType('ticket.tier')
                const roomTierPriceItems = filterItemsByType('room.tier.price')
                const ticketExperienceItems = filterItemsByType('ticket.experience.tier')
                const shoulderNightItems = filterItemsByType('room.shouldernight')
                const cartParams = {
                    eventID,
                    groupSize: groupSize || 0,
                    id: localCart.id,
                    items: [
                        ...ticketExperienceItems,
                        ...ticketItems,
                        ...roomTierPriceItems,
                        ...shoulderNightItems
                    ],
                    meta: localCart.meta,
                    presaleCode,
                    purchaseAddOnsMemberId: localCart.purchaseAddOnsMemberId,
                    hotelId: hotelID,
                    vivenuCheckoutId: data.checkout.id,
                    vivenuCheckoutSecret: data.checkout.secret,
                }
                await upsertCart(publicRuntimeConfig.NEXT_PUBLIC_ORDERS_URL!, cartParams)(dispatch);
                await router.push(nextPageUrl)
                setIsLoading(false)
            } else {
                setIsLoading(false)
                console.error('No checkout secret returned from API: ', data.checkout.message)
                return
            }
        } catch (error) {
            setIsLoading(false)
            console.error('Error creating checkout: ', error)
            return
        }
    }

    const cancelReservation = async (): Promise<void> => {
        try {
            if (tokenRef.current) {
                const { data } = await golangBackendClient.mutate({
                    mutation: CANCEL_RESERVATION_MUTATION,
                    variables: {
                        input: {
                            token: tokenRef.current
                        }
                    }
                })
                if (data.cancelReservation.id) {
                    vivenuInstanceRef.current.selectObjectsInSeatSelector([])
                    seatArrayRef.current = []
                    tokenRef.current = ''
                    setSelectedSeats([])
                    setTimer(false)
                    updateSeatingInCart({
                        seatTimer: null,
                        shouldResetSeating: false,
                        seating: [],
                        seatingToken: ''
                    })
                }
                return
            }
            return
        } catch (error) {
            console.error('Error canceling reservation: ', error)
            return
        }
    }

    const getSeatMapEventById = async (eventId: string): Promise<SeatMapEventType> => {
        try {
            const res = await fetch(`${baseUrl}/api/events/info/${eventId}`)
            if (res.ok) {
                const eventRes = await res.json()
                const { _id, categories, seatingEventId, tickets } = eventRes
                eventIdRef.current = _id
                eventRef.current = eventRes
                return {
                    id: _id,
                    categories,
                    seatingEventId,
                    tickets
                }
            }
            return { ...emptySeatMapObject }
        } catch (error) {
            console.error('Error fetching event by id: ', error)
            return { ...emptySeatMapObject }
        }
    }

    const handleSaveSeats = async (nextPageUrl: string): Promise<void> => {
        try {
            await createCheckout(seatMapEventId, nextPageUrl)
            return
        } catch (error) {
            console.error('Error saving seats: ', error)
            return
        }
    }

    const onFreeSeat = async (eventId: string, objectId: string): Promise<void> => {
        const freeInput = {
            objectId: objectId,
            seatingId: eventId,
            token: tokenRef.current
        }
        const { data } = await golangBackendClient.mutate({
            mutation: FREE_SEAT_MUTATION,
            variables: { input: freeInput }
        })
        if (data.free.status !== '201') {
            console.error('Error freeing seat: ', data.free.error)
        }
        return
    }

    // Make sure to update BOTH seatArrayRef AND selectedSeats
    const onRemoveSeat = useCallback(
        async (seatId: string): Promise<void> => {
            if (tokenRef.current) {
                try {
                    await onFreeSeat(eventRef.current.seatingEventId, seatId)
                    seatArrayRef.current = seatArrayRef.current.filter(
                        (seat) => seat.statusId !== seatId
                    )
                    const seatIds = seatArrayRef.current.map(
                        (seat: { statusId: string }) => seat.statusId
                    )
                    if (seatArrayRef.current && seatArrayRef.current.length === 0) {
                        tokenRef.current = ''
                    }
                    updateSeatingInCart({
                        checkoutId: '',
                        checkoutSecret: '',
                        seating: seatArrayRef.current ? seatArrayRef.current : [],
                        seatingToken: tokenRef.current ? tokenRef.current : '',
                        seatTimer: null,
                        shouldResetSeating: false,
                        shouldShowCountdown: false
                    })
                    setSelectedSeats([...seatArrayRef.current])
                    vivenuInstanceRef.current?.selectObjectsInSeatSelector(seatIds)
                } catch (error) {
                    console.error('Error removing seat: ', error)
                }
            } else {
                console.error('No token found for seat removal.')
            }
        },
        [onFreeSeat]
    )

    const onReserveSeat = useCallback(
        async (eventId: string, objectId: string): Promise<string> => {
            const userId = uuid()
            const reserveInput = {
                eventId: seatMapEventId,
                objectId: objectId,
                seatingId: eventId,
                token: tokenRef.current,
                userId: userId
            }
            try {
                setIsLoading(true)
                const { data } = await golangBackendClient.mutate({
                    mutation: RESERVE_SEAT_MUTATION,
                    variables: { input: reserveInput }
                })
                if (data.reserve.error !== '') {
                    console.error('Error reserving seat: ', data.reserve.error)
                    setErrorMessage(data.reserve.error)
                    setIsLoading(false)
                    return ''
                }
                if (data.reserve.token && data.reserve.objectID) {
                    if (seatArrayRef.current.length === 0 && !tokenRef.current) {
                        setTimer(true)
                        setIsReserving(true)
                        setIsTimerModalOpen(true)
                        updateSeatingInCart({
                            seatTimer: dayjs().valueOf()
                        })
                        const newToken = data.reserve.token
                        tokenRef.current = newToken
                    }
                    setIsLoading(false)
                    return data.reserve.objectID
                } else {
                    console.error('No token returned from API: ', data.reserve.error)
                    setErrorMessage(data.reserve.error)
                }
                setIsLoading(false)
                return ''
            } catch (error: any) {
                setIsLoading(false)
                console.error('Error reserving seat: ', error.message)
                setErrorMessage(error.message)
                return ''
            }
        },
        [seatMapEventId]
    )

    const onInitMap = useCallback(
        (seatingEventId: string, categories: CategoryType[], tickets: TicketType[]): void => {
            const seatingCategories = categories.map(({ ref, seatingReference, v }) => ({
                ref,
                seatingReference,
                v: v || 0
            }))
            const seatingTickets = tickets.map(({ active, categoryRef, price, v }) => ({
                active,
                categoryRef,
                price,
                v: v || 0
            }))
            if (typeof window.VIInit === 'function') {
                if (!vivenuInstanceRef.current) {
                    const instance = window.VIInit()
                    vivenuInstanceRef.current = instance

                    const updateSelectedSeats = () => {
                        const seatIds = seatArrayRef.current.map(
                            (seat: { _id: string }) => seat._id
                        )
                        if (seatIds.length > groupSize) return
                        setSelectedSeats([...seatArrayRef.current])
                        instance.selectObjectsInSeatSelector(seatIds)
                    }

                    // We may have to update this. For now, we are using the dev URL
                    instance.initSeatSelector({
                        baseUrl: seatMapUrl,
                        callbacks: {
                            onObjectDeselected: async (
                                type: any,
                                id: string,
                                resource: {
                                    statusId: string
                                }
                            ) => {
                                seatArrayRef.current = seatArrayRef.current.filter(
                                    (seat) => seat._id !== id
                                )
                                await onFreeSeat(seatingEventId, resource.statusId)
                                updateSelectedSeats()
                            },
                            onObjectSelected: async (
                                type: any,
                                id: string,
                                resource: {
                                    statusId: string
                                }
                            ) => {
                                if (seatArrayRef.current.length >= groupSize) return
                                const objectId = await onReserveSeat(
                                    seatingEventId,
                                    resource.statusId
                                )
                                if (!objectId) return
                                seatArrayRef.current = [...seatArrayRef.current, resource]
                                updateSelectedSeats()
                            },
                            onLoadDone: () => {
                                if (seating?.length && seatingToken) {
                                    seatArrayRef.current = [...seating]
                                    const seatIds = seating.map((seat: { _id: any }) => seat._id)
                                    setSelectedSeats([...seating])
                                    instance.selectObjectsInSeatSelector(seatIds)
                                }
                            }
                        },
                        eventId: seatingEventId,
                        holder: 'map',
                        options: {
                            // For categories and ticketTypes we are bound by the package
                            // the user initially chooses, so we need to be able to
                            // disallow other sections from selection
                            categories: seatingCategories,
                            minimap: {
                                enabled: isMobileWidth ? false : true
                            },
                            ticketTypes: seatingTickets,
                            tooltip: {
                                showPrice: false
                            }
                        }
                    })
                } else {
                    console.error('Vivenu instance is already initialized.')
                }
            } else {
                console.error('Vivenu function is not available yet.')
            }
        },
        [isMobileWidth, seating, seatingToken]
    )

    const unwrapSeatingEventById = useCallback(
        async (isInitial: boolean, ticketNamePayload:string): Promise<string[]> => {
            try {
                // We may have to update this. For now, we are using the dev URL
                const eventRes = await getSeatMapEventById(seatMapEventId)
                const { categories, seatingEventId, tickets } = eventRes
                const ticket = getVivenuObjectByName(ticketNamePayload ? ticketNamePayload : selectedTicketName, tickets)
                const category = getVivenuObjectByName(ticketNamePayload ? ticketNamePayload : selectedTicketName, categories)
                const namesMatch = checkNamesInArrayMatch(categories, tickets)
                if (isInitial) return namesMatch
                if (!isInitial && mapRef.current) {
                    eventIdRef.current = eventRes.id
                    eventRef.current = eventRes
                    ticketRef.current = ticket
                    onInitMap(seatingEventId, [category], [ticket])
                    return []
                }
            } catch (error) {
                console.error('Error fetching seating event by id: ', error)
                return []
            }
            return []
        },
        [getSeatMapEventById, onInitMap, seatMapEventId, selectedTicketName]
    )

    useEffect(() => {
        if (shouldResetSeating) {
            abortCheckout()
        }
    }, [shouldResetSeating])

    return {
        canContinue,
        cancelReservation,
        checkoutId,
        errorMessage,
        getSeatMapEventById,
        handleSaveSeats,
        isLoading,
        isReserving,
        isTimerModalOpen,
        mapRef,
        onRemoveSeat,
        scriptRef,
        selectedSeats,
        setErrorMessage,
        setIsTimerModalOpen,
        timeLeft,
        unwrapSeatingEventById
    }
}
