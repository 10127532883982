import { DocumentNode, gql } from '@apollo/client'

export const RESERVE_SEAT_MUTATION: DocumentNode = gql`
    mutation Reserve($input: ReserveInput!) {
        reserve(input: $input) {
            objectID
            error
            token
        }
    }
`
