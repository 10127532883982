import { useEffect, useState } from 'react'
import dayjs from 'dayjs'
import { useLocalStorage } from './useLocalStorage'

const COUNTDOWN_DURATION = 300000 // 5 mins

type CountdownTimerPropType = {
    duration?: number
    onTimeExpired: () => void
    startTimer: boolean
}

type CountdownTimerReturnType = {
    timeLeft: number
}

export const useCountdownTimer = ({
    duration = COUNTDOWN_DURATION,
    onTimeExpired,
    startTimer
}: CountdownTimerPropType): CountdownTimerReturnType => {
    const { setLocalStorage } = useLocalStorage()
    const [timeLeft, setTimeLeft] = useState<number>(duration)
    const [timerStart, setTimerStart] = useState<number | null>(null)

    useEffect(() => {
        if (startTimer) {
            const now = dayjs().valueOf()
            setTimerStart(now)
            setLocalStorage('timer', { timerStart: now })
            setTimeLeft(duration)
        }
    }, [duration, setLocalStorage, startTimer])

    useEffect(() => {
        if (timerStart !== null) {
            const intervalId = setInterval(() => {
                const elapsed = dayjs().valueOf() - timerStart
                const remaining = Math.max(duration - elapsed, 0)
                setTimeLeft(remaining)
                if (remaining === 0) {
                    clearInterval(intervalId)
                    setLocalStorage('timer', { timerStart: null })
                    onTimeExpired && onTimeExpired()
                }
            }, 1000)
            return () => clearInterval(intervalId)
        }
    }, [duration, onTimeExpired, setLocalStorage, timerStart])

    return { timeLeft }
}
