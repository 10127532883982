import { useCallback, useEffect, useRef, useState } from 'react'
import { ButtonComponent, ButtonVariant } from '../../components/Button/ButtonComponent'
import { DATE_FORMAT } from '../../enums/dateFormat'
import CloseIcon from '@mui/icons-material/Close'
import { DateRange } from '@mui/x-date-pickers-pro/'
import { DateRangePickerComponent } from '../DateRangePicker/DateRangePickerComponent'
import dayjs, { Dayjs } from 'dayjs'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import IconButton from '@mui/material/IconButton'
import Box from '@mui/material/Box'
import { FULL_WIDTH } from '../DateRangePicker/datepicker.styles'
import { ModalComponent } from '../../components/Modal'
import {
    COLOR_WHITE,
    FONT_SIZE,
    FONT_SIZE_14,
    FONT_SIZE_18,
    SIZE_20,
    SIZE_24,
    SIZE_28,
    theme
} from 'styles/theme'
import { AddToCartInfo } from 'store'
import { isMobileOnly } from 'react-device-detect'

// Keep props alphabetized
export type DateRangePickerModalProps = {
    addToCartDefaultValueRange: DateRange<Dayjs>
    addToCartValue: DateRange<Dayjs> | [null, null]
    cancelButtonTitle: string
    cartEventId?: string
    cartFirstCheckDateOfLocationTimeRange: DateRange<Dayjs> | [null, null]
    cartFirstCheckDateRange: DateRange<Dayjs> | [null, null]
    cartInfoHasSelectedHotelID: string
    cartInfoPreviousHotelID: string
    checkInDate: string | Date
    checkOutDate: string | Date
    confirmButtonTitle: string
    defaultValueRange: DateRange<Dayjs>
    eventTimezone: string
    eventHotels: any
    eventId: string
    groupSize: number
    hasAddToCart: boolean
    hasSelectedHotelID: string
    inventory: Record<string, number>
    isDisabled: boolean
    isLoading: boolean
    isOnlySellHotel: boolean
    maxDate: Dayjs | undefined
    minDate: Dayjs | undefined
    onClose: () => void
    onContinue: () => void
    onDatesChange: (value: DateRange<Dayjs>) => void
    onResetDates: () => void
    openModal: boolean
    textContent: string
    ticketId: string
    title: string
    updateEventProp?: (prop: any) => void
    value: DateRange<Dayjs> | [null, null]
}

export const DateRangePickerModalComponent = ({
    addToCartDefaultValueRange,
    addToCartValue,
    cancelButtonTitle = 'Reset',
    cartEventId,
    cartFirstCheckDateOfLocationTimeRange,
    cartFirstCheckDateRange,
    cartInfoHasSelectedHotelID,
    cartInfoPreviousHotelID,
    checkInDate,
    checkOutDate,
    confirmButtonTitle = 'Apply',
    defaultValueRange,
    eventTimezone,
    eventHotels,
    eventId,
    groupSize,
    hasAddToCart,
    hasSelectedHotelID,
    inventory,
    isDisabled = false,
    isLoading,
    isOnlySellHotel,
    maxDate,
    minDate,
    onClose,
    onContinue,
    onDatesChange,
    onResetDates,
    openModal = false,
    textContent = '',
    ticketId,
    title = '',
    updateEventProp,
    value
}: DateRangePickerModalProps): JSX.Element => {
    const cartInfo = (
        typeof window !== 'undefined' &&
        window.localStorage.getItem('addToCartInfo') !== 'undefined'
            ? JSON.parse(localStorage.getItem('addToCartInfo')!)
            : {}
    ) as AddToCartInfo
    const [isCalendarOpen, setIsCalendarOpen] = useState<boolean>(false)
    const [isMobileWidth, setIsMobileWidth] = useState<boolean>(false)
    const popperElementRef = useRef<HTMLDivElement>(null)
    const [isFirstClickModal, setIsFirstClickModal] = useState<boolean>(true)
    const handleResetDates = useCallback(() => {
        onResetDates()
    }, [onResetDates, value])
    const cartInfoComparedEventsID = cartInfo?.comparedEventsID || ''
    const hasAddToCartButInOtherEvent = cartEventId !== undefined && eventId !== cartEventId

    const closeCalendar = () => {
        setIsCalendarOpen(false)
        const newCheckIn = dayjs
            .tz(addToCartValue[0]?.format('YYYY-MM-DD'), eventTimezone)
            .toISOString()
        const newCheckOut = dayjs
            .tz(addToCartValue[1]?.format('YYYY-MM-DD'), eventTimezone)
            .toISOString()
        // When updating the date on the calendar, using the updateEventProp function will not make changes to the local cache, so we use "window.localStorage.setItem..."
        window.localStorage.setItem(
            'addToCartInfo',
            JSON.stringify({
                ...cartInfo,
                firstSelectedCheckInDate: [newCheckIn, newCheckOut]
            })
        )
    }
    useEffect(() => {
        const handleResize = () => {
            const innerWidth = window.innerWidth <= 768
            setIsMobileWidth(innerWidth)
        }
        handleResize()
        window.addEventListener('resize', handleResize)
        return () => window.removeEventListener('resize', handleResize)
    }, [])

    useEffect(() => {
        if (isCalendarOpen) {
            setIsFirstClickModal(false)
        }
    }, [isCalendarOpen])

    const onCalendarOpen = () => {
        if (isCalendarOpen) {
            setIsCalendarOpen(false)
        }
        setIsCalendarOpen(true)
        let firstSelectedCheckInDateInfo = addToCartDefaultValueRange
        if (!hasAddToCart) {
            if (
                cartInfoHasSelectedHotelID != hasSelectedHotelID ||
                eventId !== cartInfoComparedEventsID
            ) {
                onResetDates()
                firstSelectedCheckInDateInfo = addToCartDefaultValueRange
            } else {
                firstSelectedCheckInDateInfo = addToCartValue
            }
        } else {
            switch (true) {
                case hasSelectedHotelID === cartInfoPreviousHotelID:
                    firstSelectedCheckInDateInfo = cartFirstCheckDateRange
                    break
                default:
                    firstSelectedCheckInDateInfo = addToCartDefaultValueRange
                    break
            }
        }
        if (isOnlySellHotel && eventId !== cartEventId) {
            firstSelectedCheckInDateInfo = addToCartDefaultValueRange
        }
        const firstSelectedCheckInDate = dayjs
            .tz(firstSelectedCheckInDateInfo[0]?.format('YYYY-MM-DD'), eventTimezone)
            .toISOString()
        const firstSelectedCheckOutDate = dayjs
            .tz(firstSelectedCheckInDateInfo[1]?.format('YYYY-MM-DD'), eventTimezone)
            .toISOString()
        if (!isOnlySellHotel) {
            // When updating the date on the calendar, using the updateEventProp function will not make changes to the local cache, so we use "window.localStorage.setItem..."
            window.localStorage.setItem(
                'addToCartInfo',
                JSON.stringify({
                    ...cartInfo,
                    firstSelectedCheckInDate: [firstSelectedCheckInDate, firstSelectedCheckOutDate],
                    hasSelectedHotelID: hasSelectedHotelID
                })
            )
        } else {
            updateEventProp({
                ...cartInfo,
                firstSelectedCheckInDate: [firstSelectedCheckInDate, firstSelectedCheckOutDate],
                hasSelectedHotelID: hasSelectedHotelID,
                comparedEventsID: eventId
            })
        }
    }

    const getIsClickDisabled = () => {
        let disabled = true
        if (!hasAddToCart) {
            if (hasSelectedHotelID !== cartInfoHasSelectedHotelID) {
                if (!isFirstClickModal) disabled = isDisabled
            } else disabled = false
        } else {
            if (hasSelectedHotelID === cartInfoPreviousHotelID || !isFirstClickModal) {
                disabled = isDisabled
            }
        }

        if (isOnlySellHotel) {
            switch (true) {
                case !hasAddToCart &&
                    !hasAddToCartButInOtherEvent &&
                    eventId !== cartInfoComparedEventsID:
                    //!hasAddtoCart && !hasAddToCartButInOtherEvent: has not add to cart
                    disabled = true
                    break
                case isFirstClickModal:
                    disabled = true
                    break
                case eventId === cartInfoComparedEventsID:
                    disabled = isDisabled
                    break
                default:
                    disabled = true
                    break
            }
        }
        return disabled
    }

    const handleClose = () => {
        if (isCalendarOpen) {
            closeCalendar()
        }
        onClose()
    }

    const handleContinue = () => {
        if (isCalendarOpen) {
            closeCalendar()
        }
        onContinue()
    }

    const getDateValue = () => {
        let dateValue: DateRange<Dayjs> = [null, null]
        switch (true) {
            case hasAddToCart && hasSelectedHotelID === cartInfoPreviousHotelID:
                dateValue = cartFirstCheckDateOfLocationTimeRange
                break
            case hasAddToCart && !isFirstClickModal:
                dateValue = defaultValueRange
                break
            default:
                break
        }
        switch (true) {
            case !hasAddToCart &&
                !hasAddToCartButInOtherEvent &&
                hasSelectedHotelID === cartInfoHasSelectedHotelID:
                dateValue = value
                break
            case !hasAddToCart && !hasAddToCartButInOtherEvent && !isFirstClickModal:
                dateValue = defaultValueRange
                break
            default:
                break
        }
        if (isOnlySellHotel) {
            switch (true) {
                case !hasAddToCart &&
                    !hasAddToCartButInOtherEvent &&
                    eventId !== cartInfoComparedEventsID:
                    //has not add to cart
                    dateValue = [null, null]
                    break
                case isFirstClickModal:
                    dateValue = [null, null]
                    break
                case eventId === cartInfoComparedEventsID:
                    dateValue = value
                    break
                default:
                    dateValue = defaultValueRange
                    break
            }
        }
        return dateValue
    }

    const contrastHasSelectedHotelID = isOnlySellHotel
        ? cartInfoHasSelectedHotelID
        : hasSelectedHotelID

    const styles = {
        '&.MuiDialog-root': {
            zIndex: 1100
        }
    }
    return (
        <ModalComponent
            open={openModal}
            style={styles}
        >
            <Box
                sx={{ maxWidth: isMobileOnly ? '343px' : '440px' }}
                ref={popperElementRef}
            >
                <DialogTitle
                    sx={{
                        fontStyle: 'normal',
                        fontSize: FONT_SIZE_18,
                        fontWeight: 600,
                        lineHeight: SIZE_28,
                        p: 4,
                        pb: 1,
                        padding: isMobileOnly ? '20px 20px 8px 20px' : '32px 32px 8px 32px'
                    }}
                >
                    {title}
                    <IconButton
                        aria-label="close"
                        onClick={handleClose}
                        sx={{
                            position: 'absolute',
                            right: isMobileOnly ? 20 : 24,
                            top: isMobileOnly ? 24 : 28,
                            color: (theme) => theme?.palette?.grey?.[500],
                            padding: isMobileOnly ? '0px' : '8px'
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent
                    sx={{
                        pb: 0,
                        pl: 4,
                        pr: 4,
                        padding: isMobileOnly ? '0px 20px 0px' : '0px 32px 0px'
                    }}
                >
                    <DialogContentText
                        mb={3}
                        sx={{
                            fontStyle: 'normal',
                            color: theme.palette.secondary.contrastText,
                            fontSize: isMobileOnly ? FONT_SIZE_14 : FONT_SIZE,
                            lineHeight: isMobileOnly ? SIZE_20 : SIZE_24,
                            whiteSpace: 'pre-wrap',
                            marginBottom: isMobileOnly ? '16px' : '24px'
                        }}
                        variant="subtitle1"
                    >
                        {textContent}
                    </DialogContentText>
                    <DateRangePickerComponent
                        isCalendarOpen={isCalendarOpen}
                        cancelButtonTitle={cancelButtonTitle}
                        checkInDate={checkInDate}
                        checkOutDate={checkOutDate}
                        confirmButtonTitle={confirmButtonTitle}
                        defaultValueRange={defaultValueRange}
                        eventHotels={eventHotels}
                        format={openModal ? DATE_FORMAT : undefined}
                        isDisabled={isDisabled}
                        isMobileWidth={isMobileWidth}
                        isSameHotel={
                            hasAddToCart
                                ? contrastHasSelectedHotelID == cartInfoPreviousHotelID
                                : hasSelectedHotelID === cartInfoHasSelectedHotelID
                        }
                        maxDate={maxDate}
                        minDate={minDate}
                        onCalendarOpen={onCalendarOpen}
                        onConfirm={closeCalendar}
                        onDatesChange={onDatesChange}
                        onResetDates={handleResetDates}
                        eventTimezone={eventTimezone}
                        groupSize={groupSize}
                        hasAddToCart={hasAddToCart}
                        hotelId={hasSelectedHotelID}
                        inventory={inventory}
                        isFirstClickModal={isFirstClickModal}
                        isHotelPageSelected={true}
                        ticketId={ticketId}
                        value={getDateValue()}
                    />
                </DialogContent>
                <DialogActions sx={{ p: 4, padding: isMobileOnly ? '24px 20px 20px' : '32px' }}>
                    <ButtonComponent
                        disabled={getIsClickDisabled()}
                        onClick={handleContinue}
                        isLoading={isOnlySellHotel && isLoading}
                        style={{ color: COLOR_WHITE, width: FULL_WIDTH }}
                        title="Continue"
                        variant={ButtonVariant.CONTAINED}
                    />
                </DialogActions>
            </Box>
        </ModalComponent>
    )
}
