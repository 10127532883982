import { useCallback } from 'react'

export type StorageReturnType = {
    getLocalStorage: (name: string) => any
    removeLocalStorage: (name: string) => void
    setLocalStorage: (name: string, prop: any) => void
}

export const useLocalStorage = (): StorageReturnType => {
    const initialState = ''

    const getLocalStorage = useCallback(
        (name: string): any => {
            if (typeof window === 'undefined') return initialState
            try {
                const cachedData = window.localStorage.getItem(name)
                if (cachedData === null) return initialState
                try {
                    return JSON.parse(cachedData)
                } catch {
                    return cachedData
                }
            } catch (error) {
                console.error(`Error reading localStorage key "${name}":`, error)
                return initialState
            }
        },
        [initialState]
    )

    const removeLocalStorage = useCallback((name: string): void => {
        if (typeof window === 'undefined') return
        try {
            window.localStorage.removeItem(name)
        } catch (error) {
            console.error(`Error removing localStorage key "${name}":`, error)
        }
    }, [])

    const setLocalStorage = useCallback((name: string, value: any): void => {
        if (typeof window === 'undefined') return
        try {
            const data = typeof value === 'string' ? value : JSON.stringify(value)
            window.localStorage.setItem(name, data)
        } catch (error) {
            console.error(`Error setting localStorage key "${name}":`, error)
        }
    }, [])

    return {
        getLocalStorage,
        removeLocalStorage,
        setLocalStorage
    }
}
